// import { defineAsyncComponent } from 'vue';
import './DataObject.FileUpload.ts';

export { default as FileUpload, ProgressHandler } from './FileUpload.ts';
export { default as FileUploadControl } from './FileUploadControl.ts';

export { default as OUploadDrop } from './components.UploadDrop.vue';
export { default as OFileUploadButton } from './components.FileUploadButton.vue';
export { default as OFileUploadProgress } from './components.FileUploadProgress.vue';
export { default as OImageEditor } from './components.ImageEditor.vue';
// export const OImageEditor = defineAsyncComponent(() => import('./components.ImageEditor.vue').then(x => x.default));
export { default as OAttachments } from './components.Attachments.Attachments.vue';
export { default as OAttachmentUrlModal } from "./components.Attachments.UrlModal.vue";
// export const OAttachments = defineAsyncComponent(() => import('./components.Attachments.Attachments.vue').then(x => x.default));
export type { IUploads, IUploadOptions, IChunkUploaderOptions, IFileUploadOptions, UploadOptions, UploadDataOptions } from "./Types.ts";
export { DataObjectFileUpload } from "./DataObject.FileUpload.ts";
export { AttachmentsUserInterface, AttachmentsEventHandlers } from './components.Attachments.helpers.ts';